<template>
  <div class="container">
    <div class="head">
      <strong>
        <h3><a style="color: #642c90">Profile</a></h3>
      </strong>
    </div>
    <div class="divider divide hide-on-small-only"></div>
    <div class="coursecard card-panel">
      <div class="row">
        <div class="col s12 m6">
          <div class="row">
            <label for="affiliationName" class="col s12 m4 dtitle"
              >Affiliation Name<span class="required-field"></span
            ></label>
            <input
              class="col s12 m6 browser-default dropd"
              type="text"
              v-model="affiliation.name"
              ref="inputName"
            />
            <div v-if="NameValidation" class="col s12 m6 old text-left text-red">
              Please fill the Affiliation Name
            </div>
          </div>
          <div class="row">
            <label for="affiliationCode" class="col s12 m4 dtitle">Affiliation Code</label>
            <input
              class="col s12 m6 browser-default dropd"
              type="text"
              v-model="affiliation.code"
              ref="inputcode"
              readonly
            />
          </div>
          <div class="row">
            <label for="affiliationCustomUrl" class="col s12 m4 dtitle"
              >Custom Url<span class="required-field"></span
            ></label>

            <input
              class="col s12 m6 browser-default dropd"
              type="text"
              v-model="affiliation.customUrl"
              ref="input"
            />
            <div v-if="CustomUrlValidation" class="col s12 m6 l4 old text-left text-red">
              Please check CustomUrl field. CustomUrl format should be for e.g
              "http://abc.website.in".
            </div>
          </div>
          <div class="row">
            <label for="affiliationWebsite" class="col s12 m4 dtitle">Website</label>

            <input
              class="col s12 m7 browser-default dropd"
              type="url"
              v-model="affiliation.websiteUrl"
              ref="input"
              style="width: 50%"
            />
          </div>
          <div class="row">
            <label for="affiliationShortLabel" class="col s12 m4 dtitle">Short Label</label>
            <textarea
              class="col s12 m6 browser-default dropd"
              type="text"
              style="min-height: 100px"
              v-model="affiliation.shortLabel"
              ref="input"
            ></textarea>
            <!-- <div v-if="wrongshortLabel" class="col s12 m6 old text-left text-red">
              Please fill the shortLabel
            </div> -->
          </div>
          <div class="row">
            <label for="affiliationEmail" class="col s12 m4 dtitle"
              >Email Id<span class="required-field"></span
            ></label>
            <input
              class="col s12 m6 browser-default dropd"
              type="text"
              v-model="affiliation.emailId"
              ref="input"
            />
            <div v-if="EmailValidation" class="col s12 m6 old text-left text-red">
              Please fill the emailId
            </div>
          </div>
          <div class="row">
            <label for="affiliationMobile" class="col s12 m4 dtitle"
              >Mobile No<span class="required-field"></span
            ></label>

            <input
              class="col s12 m6 browser-default dropd"
              type="text"
              v-model="affiliation.mobile"
              ref="input"
            />
            <div v-if="MobileNumValidation" class="col s12 m6 old text-left text-red">
              Please fill the Mobile
            </div>
          </div>
          <div class="row">
            <label for="affiliationNameAddressline1" class="col s12 m4 dtitle"
              >AddressLine1<span class="required-field"></span
            ></label>

            <input
              class="col s12 m6 browser-default dropd"
              type="text"
              v-model="affiliation.addressLine1"
              ref="inputName"
            />
            <div v-if="AddresslineValidation" class="col s12 m6 old text-left text-red">
              Please fill the addressLine1
            </div>
          </div>
          <div class="row">
            <label for="affiliationAdddressLine2" class="col s12 m4 dtitle">AddressLine2</label>

            <input
              class="col s12 m6 browser-default dropd"
              type="text"
              v-model="affiliation.addressLine2"
              ref="inputName"
            />
            <!-- <div v-if="wrongaddressLine2" class="col s12 m6 old text-left text-red">
              Please fill the addressLine2
            </div> -->
          </div>
          <div class="row">
            <label for="affiliationState" class="col s12 m4 dtitle"
              >State<span class="required-field"></span
            ></label>
            <select
              class="browser-default dropd"
              v-model="affiliation.stateId"
              @click="onStateChange($event)"
            >
              <option value="" selected>-- select --</option>
              <option v-for="states in state" :value="states.stateId" :key="states.stateId">
                {{ states.stateName }}
              </option>
            </select>
            <div v-if="StateValidation" class="col s12 m6 old text-left text-red">
              Please Select the state
            </div>
          </div>
          <div class="row">
            <label for="affiliationCity" class="col s12 m4 dtitle"
              >City<span class="required-field"></span
            ></label>
            <select class="browser-default dropd" v-model="affiliation.cityId" @change="Result()">
              <option value="" selected>-- select --</option>
              <option :value="cities.cityId" v-for="cities in CityList" :key="cities.cityId">
                {{ cities.cityName }}
              </option>
            </select>
            <div v-if="CityValidation" class="col s12 m6 old text-left text-red">
              Please Select the city
            </div>
          </div>
          <div class="row">
            <label for="affiliationPincode" class="col s12 m4 dtitle">PinCode</label>
            <input
              class="col s12 m6 browser-default dropd"
              type="text"
              v-model="affiliation.pinCode"
              ref="inputName"
            />
          </div>
          <div class="row">
            <label for="affiliationExpDate" class="col s12 m4 dtitle">Exp Date</label>

            <input
              class="col s12 m6 browser-default dropd"
              type="datetime-local"
              v-model="affiliation.expiryDate"
              ref="input"
              readonly
            />
          </div>
          <div class="row">
            <label for="affiliationRmdetails" class="col s12 m4 dtitle">RM Details</label>

            <input
              class="col s12 m3 browser-default dropd"
              type="text"
              v-model="affiliation.rmName"
              ref="input"
              readonly
            />
            <input
              class="col s12 m3 browser-default dropd"
              type="text"
              v-model="affiliation.rmContactNo"
              ref="input"
              readonly
            />
          </div>
          <div class="row">
            <label for="affiliationrmEmail" class="col s12 m4 dtitle">RM Email Id</label>

            <input
              class="col s12 m6 browser-default dropd"
              type="text"
              v-model="affiliation.rmEmailId"
              ref="input"
              readonly
            />
          </div>
          <div class="button pt-2 center-align">
            <button
              type="button"
              class="btn btn-primary"
              @click="
                Result();
                onSubmit();
              "
            >
              Update
            </button>
          </div>
        </div>
        <div class="col s12 m6">
          <!-- <div class="col-md-1"></div> -->
          <label class="dtitle">Fav-Icon</label>
          <div class="col-md-5 col-sm-12">
            <div class="card-panel grey lighten-5">
              <div class="right-align">
                <label v-if="this.UploadIcon">
                  <i
                    class="material-icons icon"
                    @click="removeIcon"
                    style="font-size: 2rem; color: black"
                    >close</i
                  >
                </label>
              </div>
              <img :src="FileFavIcon" style="max-width: 100%" />
              <div>
                <div class="button pt-2 right-align">
                  <label for="file-favicon">
                    Upload Icon
                    <input
                      type="file"
                      id="file-favicon"
                      @change="onFileFavChange"
                      ref="inputFile"
                      accept="image/*"
                    />
                  </label>
                  <button
                    v-if="this.UploadIcon"
                    type="button"
                    class="btn btn-primary right-align"
                    @click="onSubmitLogo()"
                  >
                    Update Icon
                  </button>
                </div>
              </div>
            </div>
            <label class="dtitle">Logo</label>
            <div class="card-panel grey lighten-5">
              <div class="right-align">
                <label v-if="this.UploadLogo">
                  <i
                    class="material-icons icon"
                    @click="removeLogo"
                    style="font-size: 2rem; color: black"
                    >close</i
                  >
                </label>
              </div>
              <div class="left-align">
                <img :src="FileLogo" style="max-width: 100%" />
              </div>
              <div>
                <div class="button pt-2 right-align">
                  <label for="file-upload1">
                    Upload Logo
                    <input
                      type="file"
                      id="file-upload1"
                      @change="onFileLogoChange"
                      ref="inputFile1"
                      accept="image/*"
                    />
                  </label>
                  <button
                    v-if="this.UploadLogo"
                    type="button"
                    class="btn btn-primary right-align"
                    @click="onSubmitLogo()"
                  >
                    Update Logo
                  </button>
                </div>
              </div>
            </div>
            <label class="dtitle">Creative Images</label>
            <div class="card-panel grey lighten-5">
              <div class="right-align">
                <label v-if="this.UploadCreative">
                  <i
                    class="material-icons icon"
                    @click="removeCreativeImage"
                    style="font-size: 2rem; color: black"
                    >close</i
                  >
                </label>
              </div>
              <img :src="FileCreativeImage" style="max-width: 100%" />
              <div class="image-upload">
                <div class="right-align pt-5">
                  <label for="file-upload2">
                    Upload Creative Image1
                    <input
                      id="file-upload2"
                      type="file"
                      ref="inputFile2"
                      @change="onFileCreativeChange"
                      accept="image/*"
                    />
                  </label>
                </div>
              </div>
              <hr />
              <!-- <label class="col s12 m4 dtitle"
              >CreativeImage2</label> -->
              <div class="right-align">
                <label v-if="this.UploadCreative2">
                  <i
                    class="material-icons icon"
                    @click="removeCreativeImage2"
                    style="font-size: 2rem; color: black"
                    >close</i
                  >
                </label>
              </div>
              <img :src="FileCreativeImage2" style="max-width: 100%" />
              <div class="image-upload">
                <div class="right-align pt-5">
                  <label for="file-CreativeImage2">
                    Upload Creative Image2
                    <input
                      id="file-CreativeImage2"
                      type="file"
                      ref="inputFile3"
                      @change="onFileCreativeChange2"
                      accept="image/*"
                    />
                  </label>
                </div>
              </div>
              <hr />
              <!-- <label class="col s12 m4 dtitle"
              >CreativeImage3</label> -->
              <div class="right-align">
                <label v-if="this.UploadCreative3">
                  <i
                    class="material-icons icon"
                    @click="removeCreativeImage3"
                    style="font-size: 2rem; color: black"
                    >close</i
                  >
                </label>
              </div>
              <img :src="FileCreativeImage3" style="max-width: 100%" />
              <div class="image-upload">
                <div class="right-align pt-5">
                  <label for="file-CreativeImage3">
                    Upload Creative Image3
                    <input
                      id="file-CreativeImage3"
                      type="file"
                      ref="inputFile4"
                      @change="onFileCreativeChange3"
                      accept="image/*"
                    />
                  </label>
                </div>
                <div class="center-align">
                  <button
                    v-if="this.UploadCreative3 || this.UploadCreative2 || this.UploadCreative"
                    type="button"
                    class="btn btn-primary"
                    style="margin-top: 10px"
                    @click="onSubmitLogo()"
                  >
                    Update Creative Images
                  </button>
                </div>
              </div>
            </div>
            <label class="dtitle">DashBoard Logo</label>
            <div class="card-panel grey lighten-5">
              <div class="right-align">
                <label v-if="this.UploadDashboardUrl">
                  <i
                    class="material-icons icon"
                    @click="removeDashBoardLogo"
                    style="font-size: 2rem; color: black"
                    >close</i
                  >
                </label>
              </div>
              <img :src="FileDashBoardLogo" style="max-width: 100%" />
              <div class="image-upload">
                <div class="right-align pt-5">
                  <label for="file-upload3">
                    Upload DashBoard Logo
                    <input
                      id="file-upload3"
                      type="file"
                      ref="inputFileDashBoard"
                      @change="onFileDashboardChange"
                      accept="image/*"
                    />
                  </label>
                  <button
                    v-if="this.UploadDashboardUrl"
                    type="button"
                    class="btn btn-primary right-align"
                    @click="onSubmitLogo()"
                  >
                    Update Dashboard Logo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Doubtapi from "../Doubtapi";

export default {
  name: "AffiliationProfile",
  data() {
    return {
      affiliation: {
        name: "",
        code: "",
        customUrl: " ",
        websiteUrl: "",
        shortLabel: " ",
        emailId: "",
        mobile: " ",
        expiryDate: " ",
        faviconUrl: "",
        rmName: "",
        rmEmailId: "",
        addressLine1: "",
        addressLine2: "",
        cityId: "0",
        stateId: "0",
        pinCode: "",
        creativeImage: "",
        dashboardLogoURL: "",
        logo: "",
      },
      state: [],
      city: [],
      booleanData1: false,
      FileFavIcon: "",
      FileCreativeImage: "",
      FileCreativeImage2: "",
      FileCreativeImage3: "",
      FileLogo: "",
      FileDashBoardLogo: "",
      name: "",
      UploadLogo: "",
      UploadIcon: "",
      UploadDashboardUrl: "",
      UploadCreative: "",
      UploadCreative2: "",
      UploadCreative3: "",
      affiliationLoaded: "",
      NameValidation: false,
      customUrl: false,
      WebsiteUrlValidation: false,
      EmailValidation: false,
      AddresslineValidation: false,
      MobileNumValidation: false,
      CityValidation: false,
      StateValidation: false,
      CustomUrlValidation: false,
      Creative: [],
      CityList: [],
      //   isValid: true,
      RegExp:
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})$/gm,
    };
  },
  methods: {
    Result() {
      let isValidPage = true;
      if (this.affiliation.name === "") {
        this.NameValidation = true;
        isValidPage = false;
      } else {
        this.NameValidation = false;
      }
      if (this.affiliation.emailId === "") {
        this.EmailValidation = true;
        isValidPage = false;
      } else {
        this.EmailValidation = false;
      }
      if (this.affiliation.mobile === "") {
        this.MobileNumValidation = true;
        isValidPage = false;
      } else {
        this.MobileNumValidation = false;
      }
      if (this.affiliation.addressLine1 === "") {
        this.AddresslineValidation = true;
        isValidPage = false;
      } else {
        this.AddresslineValidation = false;
      }
      if (this.affiliation.cityId === "") {
        this.CityValidation = true;
        isValidPage = false;
      } else {
        this.CityValidation = false;
      }
      if (this.affiliation.stateId === "") {
        this.StateValidation = true;
        isValidPage = false;
      } else {
        this.StateValidation = false;
      }
      if (this.affiliation.customUrl === "") {
        this.CustomUrlValidation = true;
        isValidPage = false;
      } else {
        this.CustomUrlValidation = false;
        const regex = new RegExp(this.RegExp);
        if (this.affiliation.customUrl.match(regex)) {
          this.CustomUrlValidation = false;
        } else {
          this.CustomUrlValidation = true;
          isValidPage = false;
        }
      }
      //   if (this.affiliation.websiteUrl !== "") {
      //     const regex = new RegExp(this.RegExp);
      //     if (this.affiliation.websiteUrl.match(regex)) {
      //       this.WebsiteUrlValidation = false;
      //     } else {
      //       this.WebsiteUrlValidation = true;
      //       isValidPage = false;
      //     }
      //   }
      return isValidPage;
    },
    onStateChange(event) {
      this.affiliation.stateId = event.target.value;
      Doubtapi.getAllCity({}, (response) => {
        if (response.statusCode === 400) {
          window.M.toast({
            html: `Error:${response.data.errorMessage}`,
            classes: "rounded error",
          });
        } else if (response.statusCode === 200) {
          this.city = response.data;
          this.CityList = this.city.filter((a) => a.stateId === parseInt(event.target.value));
          //   this.affiliation.stateId = this.CityList[0].stateId;
          // this.CityList = this.city.filter(
          //   (a) => a.stateId === event.target.value,
          // );
        }
      });
    },
    onFileLogoChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      const logoType = "logo";
      //   if (!files[0] || files[0].type.indexOf("image/") !== 0) {
      //     alert("File is not an Image. Please Upload Image");
      //     this.$refs.inputFile1.value = null;
      //     return;
      //   }
      if (files[0].size > 1000000) {
        // eslint-disable-next-line
        alert("File size is more than 1MB");
        this.$refs.inputFile1.value = null;
        return;
      }
      if (!files) return;
      (async () => {
        try {
          const imageDimensions = await this.validate(files[0], logoType);
          if (imageDimensions) {
            this.createimage(files[0]);
          }
        } catch (err) {
          this.$refs.inputFile1.value = null;
        }
      })();
    },

    onFileDashboardChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      const logoType = "creative";

      //   if (!files[0] || files[0].type.indexOf("image/") !== 0) {
      //     alert("File is not an Image. Please Upload Image");
      //     this.$refs.inputFileDashBoard.value = null;
      //     return;
      //   }
      if (files[0].size > 1000000) {
        // eslint-disable-next-line
        alert("File size is more than 1MB");
        this.$refs.inputFileDashBoard.value = null;
        return;
      }
      if (!files) return;
      (async () => {
        try {
          const imageDimensions = await this.validate(files[0], logoType);
          if (imageDimensions) {
            this.createDashboardimage(files[0]);
          }
        } catch (err) {
          this.$refs.inputFileDashBoard.value = null;
        }
      })();
    },

    onFileCreativeChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      const logoType = "creative";

      //   if (!files[0] || files[0].type.indexOf("image/") !== 0) {
      //     alert("File is not an Image. Please Upload Image");
      //     this.$refs.inputFile2.value = null;
      //     return;
      //   }
      if (files[0].size > 1000000) {
        // eslint-disable-next-line
        alert("File size is more than 1MB");
        this.$refs.inputFile2.value = null;
        return;
      }
      if (!files) return;
      (async () => {
        try {
          const imageDimensions = await this.validate(files[0], logoType);
          if (imageDimensions) {
            this.createImage(files[0]);
          }
        } catch (err) {
          this.$refs.inputFile2.value = null;
        }
      })();
    },
    onFileCreativeChange2(e) {
      const files = e.target.files || e.dataTransfer.files;
      const logoType = "creative";

      //   if (!files[0] || files[0].type.indexOf("image/") !== 0) {
      //     alert("File is not an Image. Please Upload Image");
      //     this.$refs.inputFile3.value = null;
      //     return;
      //   }
      if (files[0].size > 1000000) {
        alert("File size is more than 1MB");
        this.$refs.inputFile3.value = null;
        return;
      }
      if (!files) return;
      (async () => {
        try {
          const imageDimensions = await this.validate(files[0], logoType);
          if (imageDimensions) {
            this.createImage2(files[0]);
          }
        } catch (err) {
          this.$refs.inputFile3.value = null;
        }
      })();
    },

    onFileCreativeChange3(e) {
      const files = e.target.files || e.dataTransfer.files;
      const logoType = "creative";

      //   if (!files[0] || files[0].type.indexOf("image/") !== 0) {
      //     alert("File is not an Image. Please Upload Image");
      //     this.$refs.inputFile4.value = null;
      //     return;
      //   }
      if (files[0].size > 1000000) {
        alert("File size is more than 1MB");
        this.$refs.inputFile4.value = null;
        return;
      }
      if (!files) return;
      (async () => {
        try {
          const imageDimensions = await this.validate(files[0], logoType);
          if (imageDimensions) {
            this.createImage3(files[0]);
          }
        } catch (err) {
          this.$refs.inputFile4.value = null;
        }
      })();
    },
    onFileFavChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      const logoType = "favicon";

      //   if (!files[0] || files[0].type.indexOf("image/") !== 0) {
      //     alert("File is not an Image. Please Upload Image");
      //     this.$refs.inputFile.value = null;
      //     return;
      //   }
      if (files[0].size > 1000000) {
        alert("File size is more than 1MB");
        this.$refs.inputFile.value = null;
        return;
      }
      if (!files) return;
      (async () => {
        try {
          const imageDimensions = await this.validate(files[0], logoType);
          if (imageDimensions) {
            this.createIconimage(files[0]);
          }
        } catch (err) {
          this.$refs.inputFile.value = null;
        }
      })();
    },
    validate(image, logoType) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = window.URL.createObjectURL(image);
        img.onload = function () {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          if (logoType === "creative") {
            if (width > 700 && height > 500) {
              alert("Height and Width must not exceed 500 x 700");
              window.URL.revokeObjectURL(img.src);
              reject();
            } else {
              resolve(true);
            }
          }
          if (logoType === "logo") {
            if (width > 400 && height > 100) {
              alert("Height and Width must not exceed 100 x 400");
              window.URL.revokeObjectURL(img.src);
              reject();
            } else {
              resolve(true);
            }
          }
          if (logoType === "favicon") {
            if (width > 400 && height > 100) {
              alert("Height and Width must not exceed 100 x 400");
              window.URL.revokeObjectURL(img.src);
              reject();
            } else {
              resolve(true);
            }
          }
        };
      });
    },
    createimage(file) {
      const reader = new FileReader();
      const vm = this;
      reader.onload = (e) => {
        vm.FileLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.UploadLogo = file;
    },
    createDashboardimage(file) {
      const reader = new FileReader();
      const vm = this;
      reader.onload = (e) => {
        vm.FileDashBoardLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.UploadDashboardUrl = file;
    },
    createIconimage(file) {
      const reader = new FileReader();
      const vm = this;

      reader.onload = (e) => {
        vm.FileFavIcon = e.target.result;
      };
      reader.readAsDataURL(file);
      this.UploadIcon = file;
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;

      reader.onload = function (e) {
        vm.FileCreativeImage = e.target.result;
      };
      reader.readAsDataURL(file);
      this.UploadCreative = file;
    },
    createImage2(file) {
      const reader = new FileReader();
      const vm = this;

      reader.onload = function (e) {
        vm.FileCreativeImage2 = e.target.result;
      };
      reader.readAsDataURL(file);
      this.UploadCreative2 = file;
    },
    createImage3(file) {
      const reader = new FileReader();
      const vm = this;

      reader.onload = function (e) {
        vm.FileCreativeImage3 = e.target.result;
      };
      reader.readAsDataURL(file);
      this.UploadCreative3 = file;
    },
    removeCreativeImage() {
      this.FileCreativeImage = this.Creative[0];
      this.UploadCreative = null;
      this.$refs.inputFile2.value = null;
    },
    removeCreativeImage2() {
      this.FileCreativeImage2 = this.Creative[1];
      this.UploadCreative2 = null;
      this.$refs.inputFile3.value = null;
    },
    removeCreativeImage3() {
      this.FileCreativeImage3 = this.Creative[2];
      this.UploadCreative3 = null;
      this.$refs.inputFile4.value = null;
    },
    removeLogo() {
      this.FileLogo = this.affiliation.logo;
      this.UploadLogo = null;
      this.$refs.inputFile1.value = null;
    },
    removeDashBoardLogo() {
      this.FileDashBoardLogo = this.affiliation.dashboardLogoURL;
      this.UploadDashboardUrl = null;
      this.$refs.inputFileDashBoard.value = null;
    },
    removeIcon() {
      this.FileFavIcon = this.affiliation.faviconUrl;
      this.UploadIcon = null;
      this.$refs.inputFile.value = null;
    },
    onSubmitLogo() {
      const formData = new FormData();
      if (this.UploadIcon) {
        formData.append("FileFavIcon", this.UploadIcon);
      }
      if (this.UploadLogo) {
        formData.append("FileLogo", this.UploadLogo);
      }
      if (this.UploadDashboardUrl) {
        formData.append("FileDashBoardLogo", this.UploadDashboardUrl);
      }
      if (this.UploadCreative) {
        formData.append("FileCreativeImage", this.UploadCreative);
      }
      if (this.UploadCreative2) {
        formData.append("FileCreativeImage2", this.UploadCreative2);
      }
      if (this.UploadCreative3) {
        formData.append("FileCreativeImage3", this.UploadCreative3);
      }
      formData.append("profile", JSON.stringify(this.affiliation));

      Doubtapi.updateAffiliationProfileLogo(formData, (response) => {
        if (response.statusCode === 400 || response.statusCode === 500) {
          window.M.toast({
            html: `Error:${response.data.errorMessage}`,
            classes: "rounded error",
          });
        } else if (response.statusCode === 200) {
          window.M.toast({
            html: "Updated successfully",
            classes: "rounded success",
          });
        }
      });
    },

    onSubmit() {
      if (this.Result()) {
        const formData = new FormData();
        formData.append("profile", JSON.stringify(this.affiliation));
        Doubtapi.updateAffiliationProfile(formData, (response) => {
          if (response.statusCode === 204) {
            window.M.toast({
              html: "Updated Successfully",
              classes: "rounded success",
            });
          } else {
            window.M.toast({
              html: `Error:${response.errorMessage}`,
              classes: "rounded error",
            });
          }
        });
      } else {
        window.M.toast({
          html: "Please fill in the * Required Fields",
          classes: "rounded error",
        });
      }
    },
  },
  mounted() {
    const user = this.$store.getters.user;

    Doubtapi.getAffiliationProfile(user.AffiliationId, (response) => {
      if (response.statusCode === 400) {
        window.M.toast({
          html: `Error:${response.data.errorMessage}`,
          classes: "rounded error",
        });
      } else if (response.statusCode === 200) {
        this.affiliation = response.data;
        this.affiliationLoaded = response.data;
        this.FileFavIcon = this.affiliation.faviconUrl;
        const images = this.affiliation.creativeImage;
        this.Creative = images.split(",");
        this.FileLogo = this.affiliation.logo;

        if (this.Creative.length >= 1) {
          this.FileCreativeImage = this.Creative[0];
          if (this.Creative.length > 1) {
            this.FileCreativeImage2 = this.Creative[1];
          }
          if (this.Creative.length > 2) {
            this.FileCreativeImage3 = this.Creative[2];
          }
        }
        this.FileDashBoardLogo = this.affiliation.dashboardLogoURL;
      }
    });
    Doubtapi.getAllState({}, (response) => {
      if (response.statusCode === 400) {
        window.M.toast({
          html: `Error:${response.data.errorMessage}`,
          classes: "rounded error",
        });
      } else if (response.statusCode === 200) {
        this.state = response.data;
      }
    });

    Doubtapi.getAllCity({}, (response) => {
      if (response.statusCode === 400) {
        window.M.toast({
          html: `Error:${response.data.errorMessage}`,
          classes: "rounded error",
        });
      } else if (response.statusCode === 200) {
        this.city = response.data;
        this.CityList = this.city.filter((el) => el.cityId === this.affiliation.cityId);
        this.affiliation.stateId = this.CityList[0].stateId;
        // this.CityList = this.city.filter(
        //   (a) => a.stateId === event.target.value,
        // );
      }
    });
  },
};
</script>
<style scoped>
.aff_head {
  background-color: whitesmoke;
}

.body {
  background-color: white;
  margin-top: 10px;
}

.border {
  border-color: rgb(222, 226, 230);
  height: 250px;
  display: inline-table;
}

.drop {
  height: 30px;
  border-color: rgb(222, 226, 230);
}

.old {
  color: red;
  font-size: 15px;
}

.fav {
  margin-left: 47px;
}
.dtitle {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.imagetest {
  width: 50px;
  height: 50px;
  margin-right: 200px;
}
.error {
  background-color: indianred;
  color: white;
}

.dropd {
  border-radius: 5px;
  width: 268px;
  height: 36px;
}
.required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.success {
  background-color: green;
  color: white;
}
</style>
